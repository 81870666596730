import React from 'react'
import SingleItem from '../../../react4xp/components/SingleItem'
import Button from '../../../react4xp/components/Button'


function SingleItemPart({ preHeading, heading, intro, text, imageSrc, imageSrcMedium, imageSrcSmall, videoUrl, videoAspectRatio, layout, primaryLink, primaryLinkText, secondaryLink, secondaryLinkText, backgroundColor, strings }) {
  if (!preHeading && !heading && !intro && !text && !imageSrc && !primaryLink && !primaryLink && !secondaryLink) return <></>

  return (
    <SingleItem
      layout={layout}
      labelText={preHeading}
      title={heading}
      subtitle={intro}
      extraText={text}
      imgSrc={imageSrc?.link}
      imgSrcMedium={imageSrcMedium?.link}
      imgSrcSmall={imageSrcSmall?.link}
      imgAlt={imageSrc?.alt}
      imgCaption={imageSrc?.caption}
      videoSrc={videoUrl}
      videoAspectRatio={videoAspectRatio}
      primaryLink={primaryLink}
      backgroundColor={backgroundColor}
      buttons={[
        (primaryLink && primaryLinkText) ? <Button href={primaryLink} variant={backgroundColor ? 'outline-dark' : 'default'} size='md' text={primaryLinkText} /> : <></>,
        (secondaryLink && secondaryLinkText) ? <Button href={secondaryLink} variant={backgroundColor ? 'outline-dark' : 'secondary'} size='md' text={secondaryLinkText} /> : <></>,
      ]}
      strings={strings}
    />
  )
}

export default (props) => <SingleItemPart {...props} />
